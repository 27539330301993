@import url("https://fonts.googleapis.com/css2?family=Monofett&family=Source+Code+Pro&display=swap");

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.chain-selector {
  position: relative;
  padding: 10px;
}

.omn-nav {
  position: relative;
}

.image-container {
  display: flex;
  align-items: center;
}

.selected-image {
  max-height: 50px; /* set it to your preference */
  object-fit: cover; /* maintain aspect ratio */
}

/* Dropdown positioning and size */
.dropdown {
  position: absolute;
  right: 100%; /* move it to the left of the image container */
  top: 0; /* align it to the top */
  min-width: 230px; /* set it wide enough to accommodate image and text */
  /* max-height: auto; no max-height to let it grow as needed */
  overflow-y: auto; /* add scrollbar if content overflows */
  border: solid;
  border-radius: 10px;
  cursor: default; /* Force the cursor to be the default arrow */
  background-color: #515151;
  padding: 10px;
  font-family: "Source Code Pro", monospace;
  font-size: medium;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

/* Item styling */
.dropdown div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  opacity: 100%;
}

.dropdown div:hover {
  background-color: #f8f21aff;
  color: black;
  border-radius: 10px;
}

.dropdown img {
  max-height: 50px;
  object-fit: cover;
}

.horizontal-container {
  display: flex;
  align-items: center;
}

.main-content {
  margin: auto;
  max-width: 1400px;
  padding-bottom: 90px; /* Adjust based on the height of your footer */
}

body {
  margin: auto;
  font-family: "Monofett", monospace;
  background-color: #121212;
  /* background-image: url("../public/wallpaper.png");  */
  background-repeat: repeat; /* this will tile the image */
  color: #a6e22e;
  background-color: #191e2b;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* make sure it covers full viewport height */
}

h1,
h2 {
  color: #f8f21aff;
  font-family: "Source Code Pro", monospace;
  font-size: 14pt;
}

h3 {
  color: #d6d6d6;
  font-family: "Source Code Pro", monospace;
  font-size: 12pt;
  font-weight: 400;
  color: #a6e22e;
}

.toolbar {
  height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Source Code Pro", monospace;
  font-size: large;
  background-color: #1e2025;
  border-bottom: 2px solid #f8f21aff; /* Only set the bottom border */
  color: #d6d6d6;
}

.toolbar-left img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.toolbar-right img {
  height: 35px;
  margin-right: 20px;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  /* background-color: #232323; */
  padding: 2em;
  text-align: center;
  background-image: url("../public/omn.gif");
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Establishes a context for absolutely positioned children */
}

.status {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Source Code Pro", monospace;
  background-color: #515151;
  border-radius: 10px;
  max-width: 1400 px;
}

.section {
  border-radius: 5px;
  box-shadow: 1px 1px 5px 4px rgb(248 242 26 / 50%);
  margin-bottom: 30px;
}

.internal-section {
  margin: 10pt;
  padding-bottom: 6px;
  font-family: "Source Code Pro", monospace;
}

.internal-section table {
  background-color: #2b2b2b; /* or whatever color you desire */
}

.internal-section-text-only {
  padding-left: 30px;
  padding-bottom: 6px;
  font-family: "Source Code Pro", monospace;
  word-wrap: break-word;
}

.section-header {
  background-color: #1e2025;
  color: #d6d6d6;
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: medium;
  font-weight: 600;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* This creates 2 equal columns */
  gap: 16px;
  color: #a6e22e;
}

.grid-container-single {
  display: grid;
  grid-template-columns: 1fr; /* This creates 1 equal columns */
  gap: 16px;
  color: #a6e22e;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 500px;
}

.footer-only {
  position: fixed;
  bottom: 0;
  margin: auto;
  width: 100%;
  background-color: #1e2025;
  z-index: 1000;
  text-align: center;
  border-top: 2px solid #f8f21a; /* Only set the top border */
  color: #d6d6d6;
  gap: 20px;
  justify-content: center;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
  padding-bottom: 8.5px;
  padding-top: 8.5px;
  margin-bottom: 0px;
}

.footer-text-no-border {
  width: 100%;
  text-align: center;
  margin: auto;
  gap: 20px;
  padding-bottom: 8.5px;
  padding-left: 7.5px;
  color: #d6d6d6;
  font-family: "Source Code Pro", monospace;
  font-size: 12px;
}

.horizontal-nav {
  position: fixed;
  bottom: 0;
  margin: auto;
  width: 100%;
  background-color: #1e2025;
  z-index: 1000;
  text-align: center;
  border-top: 2px solid #f8f21a; /* Only set the top border */
}

.title-bar {
  font-size: 1.2em;
  color: #d6d6d6;
  font-family: "Source Code Pro", monospace;
}

.button-container {
  display: flex;
  justify-content: center;
}

.horizontal-nav button {
  margin: 10px;
  width: 150px;
  min-height: 30px;
  cursor: pointer;
  border: 1px;
  border-style: solid;
  border-color: #d6d6d6;
  border-radius: 10px;
  background: black;
  color: #d6d6d6;
  font-family: "Source Code Pro", monospace;
  font-size: medium;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition */
}

/* Hover effect */
.horizontal-nav button:hover {
  background-color: #f8f21a; /* New background color */
  color: black; /* New text color */
  border: 1px;
  border-style: solid;
  opacity: 100%;
}

.external-link-button {
  margin: 10px;
  width: 150px;
  min-height: 30px;
  cursor: pointer;
  border-radius: 10px;
  background: black;
  border: 1px;
  border-style: solid;
  border-color: #d6d6d6;
  color: #d6d6d6;
  font-family: "Source Code Pro", monospace;
  font-size: medium;
  text-decoration: none; /* Removes underline */
  display: inline-flex; /* Makes it possible to align text centrally */
  justify-content: center; /* Centers text horizontally */
  align-items: center; /* Centers text vertically */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.external-link-button:hover {
  background-color: #f8f21a; /* New background color */
  color: black; /* New text color */
  border: 1px;
  border-style: solid;
  opacity: 100%;
}

.search-data-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.search-data-label {
  display: block;
  font-family: "Source Code Pro", monospace;
  font-size: x-large;
}

.input-wrapper {
  position: relative;
  display: inline-block;
  width: 800px;
}

.search-data-input {
  display: inline-block;
  margin: 10px auto;
  font-size: 16px;
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  text-align: left; /* Text will appear centered */
  z-index: 1;
  color: #d6d6d6;
  font-size: large;
  text-align: center;
  font-family: "Source Code Pro", monospace;
}

.underscore {
  position: absolute;
  bottom: 0;
  left: 262px; /* Start from the leftmost part */
  width: 280px; /* Extend to the full width of the parent .input-wrapper */
  height: 2px;
  background-color: #f8f21aff;
  opacity: 0;
  animation: flash 1s infinite;
  z-index: 0;
}

.underscore-hidden {
  opacity: 0;
  text-align: center;
}

.centered-container {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  height: calc(
    100vh - 80px - 145px - 145px - 32px
  ); /* Adjust this as needed, 100vh means it will take the full height of the viewport */
}

@keyframes flash {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.retrieve-button {
  display: block; /* Block level element */
  margin: 0 auto; /* Auto margins for horizontal centering */
  padding: 10px 20px; /* Padding for better appearance */
  border-radius: 12px; /* Rounded corners */
  background-color: #0056b3; /* Background color */
  color: #d6d6d6; /* Text color */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  border: none;
}

/* Optionally, you can add some hover effect */
.retrieve-button:hover {
  background-color: #1a8ff8; /* Slightly darker background on hover */
  color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #d6d6d6;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
